import * as yup from 'yup';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import useContentElement from '../../hooks/useContentElement';

export default function MediaElement(props) {
  const { data, isMediaSvg, ...otherProps } = props;
  const classes = useStyles();
  const { elementData } = useContentElement(
    data,
    MediaElement.dataSchema,
  );

  // Set default values
  const {
    file,
    metaAlt,
    objectPositionX,
    objectPositionY,
    scaling,
  } = elementData;

  if (!file) {
    return null;
  }

  switch (file.mime) {
    default:
      return (
        <MediaImage
          alt={otherProps.alt ?? metaAlt}
          classes={classes}
          file={file?.data?.attributes}
          positionX={objectPositionX}
          positionY={objectPositionY}
          style={scaling && !isMediaSvg ? {
            width: SCALING_MAP[scaling ?? 'normal'],
          } : null}
          {...otherProps}
        />
      );
  }
}

function MediaImage(props) {
  const {
    file,
    alt,
    classes,
    isSquare,
    isFluid,
    isAbsoluteFill,
    positionX,
    positionY,
    className,
    isLazy,
    ...otherProps
  } = props;
  const url = file.url ?? '';
  const style = { ...otherProps.style };

  const imgClasses = [
    classes.root,
    (!isSquare && !isAbsoluteFill) ? classes.rootRounded : null,
    className,
  ];

  if (isFluid) {
    imgClasses.push(classes.fluid);
  }

  if (isAbsoluteFill) {
    imgClasses.push(classes.absoluteFill);
  }

  if (positionX || positionY) {
    style.objectPosition = `${positionX ?? 'center'} ${positionY ?? 'center'}`.toLowerCase();
  }

  return (
    <img
      alt={alt}
      className={clsx(imgClasses)}
      loading={isLazy ? 'lazy' : 'eager'}
      src={url}
      style={style}
      {...otherProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    objectFit: 'cover',
  },
  rootRounded: {
    borderRadius: theme.shape.borderRadius,
  },
  fluid: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
  absoluteFill: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const SCALING_MAP = {
  tiny: '25%',
  small: '50%',
  smaller: '75%',
  normal: '100%',
  larger: '125%',
  large: '150%',
  big: '175%',
};

MediaElement.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.shape({
    file: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.string,
        attributes: PropTypes.shape({
          formats: PropTypes.object,
          url: PropTypes.string,
        }),
      }),
    }),
    caption: PropTypes.string,
    metaAlt: PropTypes.string,
    objectPositionX: PropTypes.string,
    objectPositionY: PropTypes.string,
    scaling: PropTypes.oneOf([null, ...Object.keys(SCALING_MAP)]),
  }),
  isFluid: PropTypes.bool,
  isSquare: PropTypes.bool,
  isMediaSvg: PropTypes.bool,
  isAbsoluteFill: PropTypes.bool,
};
MediaElement.defaultProps = {
  data: {
    file: null,
    caption: '',
    metaAlt: '',
    scaling: 'normal',
  },
  isFluid: true,
};
MediaElement.dataSchema = yup.object().shape({
  file: yup.object().shape({
    data: yup.object().shape({
      id: yup.string().nullable(),
      attributes: yup.object().shape({
        formats: yup.object().nullable(),
        url: yup.string(),
      }).nullable(),
    }),
  }).nullable(),
  caption: yup.string().nullable(),
  metaAlt: yup.string().nullable(),
  objectPositionX: yup.string().nullable(),
  objectPositionY: yup.string().nullable(),
  scaling: yup.string().oneOf([null, ...Object.keys(SCALING_MAP)]).nullable(),
});

MediaElement.graphQlSchema = `
  file {
    data {
      id
      attributes {
        name
        createdAt
        alternativeText
        caption
        width
        height
        formats
        hash
        ext
        mime
        size
        url
      }
    }
  }
  caption
  metaAlt
  objectPositionX
  objectPositionY
  scaling
`;
